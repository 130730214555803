<template>
  <div class="statistics">
			<div style="text-align: right;">
				<el-select v-model="formSearch.timeScope" @change="handleTimeScope" style="width: 110px;">
					<el-option label="一周内" :value="1"></el-option>
					<el-option label="一个月内" :value="2"></el-option>
					<el-option label="三个月内" :value="3"></el-option>
					<el-option label="半年内" :value="4"></el-option>
					<el-option label="一年内" :value="5"></el-option>
				</el-select>
			</div>
		<div style="margin: 10px 0;">
			<el-row :gutter="0">
				<el-col :span="7">
					<div class="content-box" style="margin: 0;"v-loading="loadingFlag1">
						<h4 class="title-h4">访问模块分布</h4>
						<div style="height: 200px;" id="echartsPie"></div>
					</div>
				</el-col>
				<el-col :span="9">
					<div class="content-box" style="margin: 0 10px;" v-loading="loadingFlag2">
						<h4 class="title-h4">登录时间分布</h4>
						<div id="echartsLineArea" style="height: 200px;"></div>
					</div>
				</el-col>
				<el-col :span="8">
					<div class="content-box" style="margin: 0;" v-loading="loadingFlag3">
						<h4 class="title-h4">
							<b v-for="(item,index) in numList" :key="index" :class="logTypeIndex==index ? 'color-m' : ''" @click="handeLogType(item.value,index)" style="cursor: pointer;">{{index == 0 ? item.name+' | ' : item.name}}</b>
						</h4>
						<div id="echartsBar" style="height: 200px;"></div>
					</div>
				</el-col>
			</el-row>
		</div>
		<div class="search-box">
			<el-form :inline="true" :model="formSearch" class="demo-form-inline" label-width="100px">
				<el-form-item label="用户名">
					<el-input v-model="formSearch.account" placeholder="请输入用户名"></el-input>
				</el-form-item>
				<el-form-item label="姓名">
					<el-input v-model="formSearch.name" placeholder="请输入姓名"></el-input>
				</el-form-item>
				<el-form-item label="所属部门">
					<el-select v-model="formSearch.departId" clearable>
						<el-option v-for="(item,index) in formSearch.departIdList" :key="index" :label="item.name" :value="item.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="状态">
					<el-select v-model="formSearch.status" clearable>
						<el-option label="活跃" value="1"></el-option>
						<el-option label="不活跃" value="0"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item style="text-align: right;" class="fr">
					<el-button type="primary" @click="handleSearch">检索</el-button>
				</el-form-item>
			</el-form>
		</div> 
		<!-- 列表 -->
		<div class="table-box">
			<el-card>
				<el-table v-loading="loadingFlag" border :data="tableData" @sort-change='sortChange' :default-sort="{prop: 'loginCount', order: 'descending'}" @selection-change="handleSelectionChange" :row-style="{height:'48px'}" empty-text="加载中..." style="width: 100%;">
					<el-table-column type="index" :index="typeIndex" label="序号" width="50"></el-table-column>
					<el-table-column prop="userName" label="用户名"></el-table-column>
					<el-table-column prop="realName" label="姓名"></el-table-column>
					<el-table-column prop="roleName" label="用户角色"></el-table-column>
					<el-table-column prop="departName" label="所属部门"></el-table-column>
					<el-table-column prop="status" label="状态" width="100">
						<template slot-scope="scope">
							<el-button type="primary" size="mini" circle v-if="scope.row.status == '活跃'" style="float: left;margin: 3px 5px 0 15px;"></el-button>
							<el-button type="warning" size="mini" circle v-if="scope.row.status == '不活跃'" style="float: left;margin: 3px 5px 0 15px;"></el-button>
							<span style="float: left;">{{scope.row.status}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="lastLoginTime" label="最晚登录时间" sortable="custom"></el-table-column>
					<el-table-column prop="loginCount" label="登录次数" sortable="custom"></el-table-column>
					<el-table-column prop="lastOperationTime" label="最晚操作时间" sortable="custom"></el-table-column>
					<el-table-column prop="operationCount" label="操作次数" sortable="custom"></el-table-column>
					<template slot="empty">
						<img v-if="dataTableEmpty" class="data-pic" :src="`${baseUrl}img/bt-nodata-now.png`" alt="" style="display: block;margin: 100px auto;" />
					</template>
				</el-table>
				<!-- <el-pagination @current-change="handleCurrentChange" :total="totalItems" :current-page="currentPage" :page-size="pageSize" layout="total, prev, pager, next, jumper"></el-pagination> -->
			</el-card>
		</div>
  </div>
</template>

<script>
import { echartsPieHuan,echartsLineArea,echartsBarHeng } from "../../common/js/chart.js"
export default {
  name: 'statistics',
  data() {
		return {
			baseUrl:this.$pulick.baseUrl,//默认路径目录  加载静态文件img
			loadingFlag1: false,
			loadingFlag2: false,
			loadingFlag3: false,
			logTypeIndex:0,
			numList:[
				{name:'登录次数排行',value:1},
				{name:'操作次数排行',value:0}
			],
			formSearch: {
				timeScope:1,
				logType:1,
				account: '',
				name: '',
				departId: '',
				departIdList:[],
				status:'',
				prop: 'loginCount',
				order: 'desc',
			},
			loadingFlag: false,
			dataTableEmpty:false,
			tableData: [], //列表  数组
			currentPage: 1, //当前页
			pageSize: 15, //每页  条数
			totalItems: 0, //列表 总数
		}
  },
  created() {
		this.getCountByRisk(); //访问模块分布
		this.getCountByInsertTime(); //登录时间分布趋势
		this.getBistributionMap(); //登录排行
		this.getDepartList(); //加载部门
		this.getPageList(); //加载列表
  },
  methods: {
		//时间范围 
		handleTimeScope(){
			this.getCountByRisk(); //访问模块分布
			this.getBistributionMap(); //登录排行
			this.getPageList(); //加载列表
		},
		//访问模块分布
		getCountByRisk() {
			this.loadingFlag1 = true; //loading 显示
			this.$provider.get('/wg-system/log/countModule', {
				params: {
					timeScope:this.formSearch.timeScope,
				}
			}).then(res => {
				this.loadingFlag1 = false; //loading 消失
				if (res.code == 200) {
					let data = res.data;
					let legendData = [];
					let seriesData = [];
					for (let i = 0; i < data.length; i++) {
						seriesData.push({
							value: Number(data[i].count),
							name: data[i].module
						});
						legendData.push(data[i].module);
					}
					let resultdata = {
						legendData: legendData,
						seriesData: seriesData,
					}
					let colorList = ['#FF686F', '#FFB22E', '#269CF6', '#1DCC7F', '#40EED5', '#AFA3F5'];
					echartsPieHuan("echartsPie", resultdata, colorList);
				}
			})
		},
		//登录时间分布趋势
		getCountByInsertTime() {
			this.loadingFlag2 = true; //loading 显示
			this.$provider.get('/wg-system/log/countEveryDay', {}).then(res => {
				this.loadingFlag2 = false; //loading 消失
				if (res.code == 200) {
					let data = res.data;
					let legendData = [];
					let seriesData = [];
					for (let i = 0; i < data.length; i++) {
						legendData.push(data[i].date);
						seriesData.push(Number(data[i].count));
					}
					echartsLineArea("echartsLineArea", legendData, seriesData, '#FFB922');
				}
			})
		},
		//加载排行
		getBistributionMap() {
			this.loadingFlag3 = true; //loading 显示
			this.$provider.get('/wg-system/log/countLogPage', {
				params: {
					timeScope:this.formSearch.timeScope,
				}
			}).then(res => {
				this.loadingFlag3 = false; //loading 消失
				var data = this.formSearch.logType==1 ? res.data.loginCountMap : res.data.operatorCountMap;
				let resultdata = [];
				for (let i = 0; i < data.length; i++) {
					resultdata.push({
						name: data[i].userName,
						value: Number(data[i].count)
					})
				}
				echartsBarHeng("echartsBar", resultdata, "jc"); //地域排名
			})
		},
		handeLogType(value,index){
			this.formSearch.logType = value;
			this.logTypeIndex = index;
			this.getBistributionMap();
		},
		
		//加载部门  省    检索
		getDepartList() {
			this.$provider.get('/wg-system/depart/list', {
				params: {
					parentCode: 660000
				}
			}).then(res => {
				this.formSearch.departIdList = res.data;
			})
		},
		//点击查询  按钮
		handleSearch() {
			this.currentPage = 1; //初始第一页
			this.getPageList(); //加载列表
		},
		//加载列表
		getPageList() {
			this.loadingFlag = true;//loading 显示
			this.dataTableEmpty = false;
			this.$provider.get('/wg-system/log/countLogPage', {
				params: {
					timeScope:this.formSearch.timeScope,
					account: this.formSearch.account,
					name: this.formSearch.name,
					departId: this.formSearch.departId,
					status: this.formSearch.status,
					prop: this.formSearch.prop,
					order: this.formSearch.order,
					current: this.currentPage,
					size: this.pageSize,
				}
			}).then(res => {
				this.loadingFlag = false;//loading 消失
				this.dataTableEmpty = true;
				this.tableData = res.data.page.records;
				this.totalItems = res.data.page.total;
			})
		},
		//处理分页数据的 index
		typeIndex(index) {
			return (this.currentPage - 1) * this.pageSize + index + 1;
		},
		//当前页面  属于第几页
		handleCurrentChange(val) {
			this.currentPage = val;
			this.getPageList(); //加载列表
		},
		//排序
		sortChange(column, prop, order) {
			if (column.order == "ascending") {
				this.formSearch.prop = column.prop;
				this.formSearch.order = "asc";
			} else if (column.order == "descending") {
				this.formSearch.prop = column.prop;
				this.formSearch.order = "desc";
			} else {
				this.formSearch.prop = "";
				this.formSearch.order = "";
			}
			this.getPageList(); //加载列表
		},
  }
};
</script>

<style scoped="scoped"></style>
